import { Link } from 'gatsby'
import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'

import '../styles/contact.scss'

class Contact extends React.Component {
  constructor() {
    super()
    this.state = {
      //text: inputのvalueを管理
      //status: formをsubmitできるかのstatusを管理
      nameText: '',
      nameStatus: null,
      emailText: '',
      emailStatus: null,
      contactText: '',
      contactStatus: null,
      agreeStatus: null,
      textLimit: 400,
    }
  }
  componentDidMount() {
    const script = document.createElement('script')

    script.src = 'https://sdk.form.run/js/v2/formrun.js'
    script.async = true

    document.body.appendChild(script)

    $('[data-toggle="tooltip"]').tooltip()
  }
  renderButton() {
    const st = this.state
    if (st.agreeStatus && st.emailStatus && st.nameStatus && st.contactStatus) {
      return (
        <button className="submit-btn allowed" type="submit">
          送信
        </button>
      )
    } else {
      return (
        <span className="submit-btn un-allowed d-flex justify-content-center align-items-center">
          送信
        </span>
      )
    }
  }
  handleChange(e, type) {
    const tx = e.target.value
    switch (type) {
      case 'name':
        this.setState({ nameText: tx, nameStatus: tx.length > 0 })
        break
      case 'email':
        const emailInfo = tx.length > 0 && tx.match(/[^\s]@[^\s]/)
        const status =
          emailInfo !== null && emailInfo !== false && emailInfo[0].length > 0
        this.setState({ emailText: tx, emailStatus: status })
        break
      case 'contact':
        this.setState({
          contactText: tx,
          contactStatus: tx.length > 0 && tx.length < this.state.textLimit,
        })
    }
  }
  render() {
    const { location, data } = this.props
    const style = {
      position: 'absolute!important',
      height: '1px',
      width: '1px',
      overflow: 'hidden',
    }

    return (
      <Layout location={location}>
        <Meta site={siteMetadata} title="Contact" />
        <section className="contact">
          <h2 className="mb-3">
            <span className="title">お問い合わせ</span>
            <span className="title-shadow">Contact</span>
          </h2>

          <form
            className="formrun px-md-5 px-sm-2 px-0 d-flex justify-content-center"
            action="https://form.run/api/v1/r/jol9epjdor5ncy1f8itqzwld"
            method="post"
          >
            <div className="formbox-wrap pt-3">
              <div className="formbox">
                <div className="label-wrap">
                  <label className="category">
                    <span>お名前</span>
                    <span className="badge required">必須</span>
                    <span
                      className={`far fa-check-circle judge-mark check-mark
                      ${
                        this.state.nameStatus === null ? 'unvisible' : 'visible'
                      }
                      ${
                        this.state.nameStatus === false
                          ? 'd-none'
                          : 'd-inline-block'
                      }
                      `}
                    />
                    <span
                      className={`far fa-times-circle judge-mark x-mark
                      ${
                        this.state.nameStatus === null ? 'unvisible' : 'visible'
                      }
                      ${
                        this.state.nameStatus === true
                          ? 'd-none'
                          : 'd-inline-block'
                      }
                      `}
                      data-toggle="tooltip"
                      title="入力してください"
                    />
                  </label>
                </div>
                <input
                  name="client-name"
                  type="text"
                  onChange={e => this.handleChange(e, 'name')}
                />
              </div>
              <div className="formbox">
                <div className="label-wrap">
                  <label className="category align-middle">
                    <span className="d-flex align-items-center">
                      <span>メールアドレス</span>
                      <span className="badge required">必須</span>
                      <span
                        className={`far fa-check-circle judge-mark check-mark
                        ${
                          this.state.emailStatus === null
                            ? 'unvisible'
                            : 'visible'
                        }
                        ${
                          this.state.emailStatus === false
                            ? 'd-none'
                            : 'd-inline-block'
                        }
                        `}
                      />
                      <span
                        className={`far fa-times-circle judge-mark x-mark
                        ${
                          this.state.emailStatus === null
                            ? 'unvisible'
                            : 'visible'
                        }
                        ${
                          this.state.emailStatus === true
                            ? 'd-none'
                            : 'd-inline-block'
                        }
                        `}
                        data-toggle="tooltip"
                        title="正しく入力してください"
                      />
                    </span>
                  </label>
                </div>
                <input
                  name="email-addres"
                  type="text"
                  onChange={e => this.handleChange(e, 'email')}
                />
              </div>
              <div className="formbox">
                <div className="label-wrap">
                  <label className="category">
                    <span>お問い合わせ</span>
                    <span className="badge required">必須</span>
                    <span
                      className={`far fa-check-circle judge-mark check-mark
                        ${
                          this.state.contactStatus === null
                            ? 'unvisible'
                            : 'visible'
                        }
                        ${
                          this.state.contactStatus === false
                            ? 'd-none'
                            : 'd-inline-block'
                        }
                        `}
                    />
                    <span
                      className={`far fa-times-circle judge-mark x-mark
                        ${
                          this.state.contactStatus === null
                            ? 'unvisible'
                            : 'visible'
                        }
                        ${
                          this.state.contactStatus === true
                            ? 'd-none'
                            : 'd-inline-block'
                        }
                        `}
                      data-toggle="tooltip"
                      title="正しく入力してください"
                    />
                  </label>
                </div>
                <textarea
                  rows="8"
                  name="contact-content"
                  onChange={e => this.handleChange(e, 'contact')}
                ></textarea>
                <div className="d-flex justify-content-end">
                  <span className="warning">
                    残り{this.state.textLimit - this.state.contactText.length}
                    文字
                  </span>
                </div>
              </div>

              <div className="checkbtn d-flex flex-md-row flex-column">
                <div className="label-wrap d-flex align-items-center justify-content-start">
                  <div className="checkbox-wrap d-inline-block">
                    <label className="category">
                      <span>個人情報利用同意</span>
                      <a target="_blank" href="/privacy-contact">
                        (個人情報の取扱いについて)
                      </a>
                      <span className="badge required">必須</span>
                    </label>
                  </div>
                  <input
                    className="d-none"
                    id="agree-checkbox"
                    type="checkbox"
                    onClick={() =>
                      this.setState({ agreeStatus: !this.state.agreeStatus })
                    }
                  />
                  <label
                    className="agree-checkbox"
                    htmlFor="agree-checkbox"
                  ></label>
                </div>
                <div
                  className={`warning d-flex justify-content-center align-items-center
                    ${
                      this.state.agreeStatus === null ||
                      this.state.agreeStatus === true
                        ? 'unshowed'
                        : 'showed'
                    }
                    `}
                >
                  同意してください
                </div>
              </div>

              {/* <div className="_formrun_gotcha" style={style}>
                <label htmlFor="_formrun_gotcha">
                  If you are a human, ignore this field
                </label>
                <input
                  type="text"
                  name="_formrun_gotcha"
                  id="_formrun_gotcha"
                  tabIndex="-1"
                />
              </div> */}
              <div
                className="g-recaptcha"
                data-sitekey="6LdFFmkaAAAAAMN8r9Oq0JWcyR2HLBpsSobfvJ5e"
              ></div>
              <div className="btn-wrap d-flex justify-content-end mt-4">
                {this.renderButton()}
              </div>
            </div>
          </form>
        </section>
      </Layout>
    )
  }
}

export default Contact
